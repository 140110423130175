const routes = {
    Mainpage: '/',
    ContactPage: '/contactus',
    privacyPolicy:"/privacypolicy",
Support:"/support",
TandC:"/TermsAndConditions",
CancelAndRefunds:"/cancellationsAndRefunds",
GrievAndRedressal:"/grievancesAndRedressal",


}
export default routes;