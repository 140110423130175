import React , { Suspense }  from 'react';
import OurRoutes from './Route';
import * as Routers from './Router';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function AppRouter() {
    return (
        <Router>
            <Suspense fallback={'...'}>
                    <Routes>
                        <Route path={OurRoutes.Mainpage} element={<Routers.Mainpage />}> 
                            <Route index element={<Routers.HomePage />} />
                            <Route path={OurRoutes.ContactPage} element={<Routers.ContactPage />} />
                            <Route path={OurRoutes.privacyPolicy} element={<Routers.PrivacyPolicy/>} />
                            <Route path={OurRoutes.CancelAndRefunds} element={<Routers.CancelAndRefund/>}/>
                            <Route path={OurRoutes.GrievAndRedressal} element={<Routers.GrievAndRedressal/>} />
                            <Route path={OurRoutes.TandC} element={<Routers.TAndC/>} />
                            <Route path={OurRoutes.Support} element={<Routers.Support/>}/>

                        </Route>
                    </Routes>
                    </Suspense>
        </Router>
    );
}

export default AppRouter;
